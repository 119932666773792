body {
  position: relative;
  background-image: url('./styles/mint.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  max-width: 1150px;
  width: 90%;
  height: auto;
  margin-top: 100px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #000000D1;
  border-radius: 22px;
  margin-bottom: 50px;
  padding: 30px;
}

.bg {
  width: 100%;
  margin-top: 25px;
  border-radius: 20px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.title-image {
  width: 100%;
  max-width: 500px;
}

.subtitle {
  margin-top: 40px;
  font-size: 25px;
  color: white;
}

.mintAmount {
  font-size: 50px;
  color: white;
  margin-top: 50px;
}

.title {
  margin-top: -310px;
  font-size: 55px;
  font-weight: 600;
  color: white;
}

.paragraph {
  color: #EA3323;
  font-size: 40px;
  margin-bottom: 0px;
}

.paragraph2 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.button {
  display: inline-block;
  padding: 22px 41.8438px 22px 42px;
  margin: 30px auto;
  margin-left: 20px;
  margin-right: 20px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.252);
  border-radius: 25px;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  border: #EA3323;
  border-style: solid;
  cursor: pointer;
}

.supply-container{
  align-items: flex-start;
  padding: 22px 41.8438px 22px 42px;
  width: 200px;
  height: 65px;
  margin-left: auto;
  margin-right: 20px;
  justify-content: center;
  margin-top: 50px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.252);
  border-radius: 25px;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  border: #EA3323;
  border-style: solid;
  cursor: pointer;
  }

  .supply-container2{
    align-items: flex-start;
    padding: 22px 41.8438px 22px 42px;
    width: 200px;
    height: 65px;
    margin-right: auto;
    margin-left: 20px;
    justify-content: center;
    margin-top: 50px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.252);
    border-radius: 25px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    border: #EA3323;
    border-style: solid;
    cursor: pointer;
    }

.increment {
  display: inline-block;
  padding: 15px 25px 15px 25px;
  margin: 30px auto;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.252);
  border-radius: 25px;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  border: #EA3323;
  border-style: solid;
  cursor: pointer;
}

.supply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 40px;
    margin-top: 20px;
  }

  .mintAmount {
    font-size: 35px;
  }

  .paragraph {
    font-size: 30px;
  }

  .subtitle {
    font-size: 20px;
    margin-top: 20px;
  }


  .button,
  .supply-container,
  .supply-container2,
  .increment {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }

  .title-image {
    width: 60%;
  }

  .container {
    padding: 20px;
  }
}

